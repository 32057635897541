<template>
  <div class="circle-stencil-wrapper">
    <!-- Custom overlay with a circular hole -->
    <div class="overlay-wrapper">
      <div class="overlay">
        <div class="hole" :style="holeStyle"></div>
      </div>
    </div>

    <div :style="stencilStyle" class="circle-stencil">
      <draggable-area
        :stencil-coordinates="stencilCoordinates"
        @move="$emit('move', $event)"
        @move-end="$emit('move-end')"
      >
        <stencil-preview
          class="circle-preview"
          :image="image"
          :coordinates="coordinates"
          :width="stencilCoordinates.width"
          :height="stencilCoordinates.height"
          :transitions="transitions"
        />
      </draggable-area>

      <!-- Custom resize handles -->
      <div
        v-for="handler in handlers"
        :key="handler"
        :class="['resize-handle', handler]"
        @mousedown.stop="onResizeStart($event, handler)"
        @touchstart.stop="onResizeStart($event, handler)"
      ></div>
    </div>
  </div>
</template>

<script>
import { DraggableArea, StencilPreview } from 'vue-advanced-cropper';

export default {
  name: 'CircleStencil',
  components: {
    DraggableArea,
    StencilPreview
  },
  props: {
    image: Object,
    coordinates: Object,
    stencilCoordinates: Object,
    transitions: Object,
    stencilSize: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      handlers: ['n', 's', 'e', 'w'],
      resizing: false,
      currentHandler: null,
      startX: 0,
      startY: 0,
      startWidth: 0,
      startHeight: 0,
      startLeft: 0,
      startTop: 0
    };
  },
  computed: {
    stencilStyle() {
      const { left = 0, top = 0, width = 200, height = 200 } = this.stencilCoordinates || {};
      return {
        position: 'absolute',
        top: `${top}px`,
        left: `${left}px`,
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: '50%',
        border: '2px dashed white',
        boxSizing: 'border-box',
      };
    },
    holeStyle() {
      const { left = 0, top = 0, width = 200, height = 200 } = this.stencilCoordinates || {};
      return {
        position: 'absolute',
        top: `${top}px`,
        left: `${left}px`,
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: '50%',
        background: 'transparent',
      };
    },
  },
  mounted() {
    window.addEventListener('mousemove', this.onResize);
    window.addEventListener('mouseup', this.onResizeEnd);
    window.addEventListener('touchmove', this.onResize, { passive: false });
    window.addEventListener('touchend', this.onResizeEnd);
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.onResize);
    window.removeEventListener('mouseup', this.onResizeEnd);
    window.removeEventListener('touchmove', this.onResize);
    window.removeEventListener('touchend', this.onResizeEnd);
  },
  methods: {
    onResizeStart(event, handler) {
      event.preventDefault();
      event.stopPropagation();

      this.resizing = true;
      this.currentHandler = handler;

      const touch = event.touches ? event.touches[0] : event;
      this.startX = touch.clientX;
      this.startY = touch.clientY;

      this.startWidth = this.stencilCoordinates.width;
      this.startHeight = this.stencilCoordinates.height;
      this.startLeft = this.stencilCoordinates.left;
      this.startTop = this.stencilCoordinates.top;

      this.$emit('resize-start');
    },

    onResize(event) {
      if (!this.resizing) return;
      if (event.preventDefault) event.preventDefault();

      const touch = event.touches ? event.touches[0] : event;
      const deltaX = touch.clientX - this.startX;
      const deltaY = touch.clientY - this.startY;

      const directions = { left: 0, right: 0, top: 0, bottom: 0 };

      if (this.currentHandler === 'nw') {
        directions.left = -deltaX;
        directions.top = -deltaY;
      } else if (this.currentHandler === 'ne') {
        directions.right = deltaX;
        directions.top = -deltaY;
      } else if (this.currentHandler === 'sw') {
        directions.left = -deltaX;
        directions.bottom = deltaY;
      } else if (this.currentHandler === 'se') {
        directions.right = deltaX;
        directions.bottom = deltaY;
      } else if (this.currentHandler === 'n') {
        directions.top = -deltaY;
        directions.left = -deltaY / 2;
        directions.right = -deltaY / 2;
      } else if (this.currentHandler === 's') {
        directions.bottom = deltaY;
        directions.left = deltaY / 2;
        directions.right = deltaY / 2;
      } else if (this.currentHandler === 'e') {
        directions.right = deltaX;
      } else if (this.currentHandler === 'w') {
        directions.left = -deltaX;
      }

      let maxDirection = 0;
      if (this.currentHandler.includes('n') || this.currentHandler.includes('s')) {
        maxDirection = Math.max(Math.abs(directions.top), Math.abs(directions.bottom));
      } else if (this.currentHandler.includes('e') || this.currentHandler.includes('w')) {
        maxDirection = Math.max(Math.abs(directions.left), Math.abs(directions.right));
      } else {
        maxDirection = Math.max(
          Math.abs(directions.left), 
          Math.abs(directions.right),
          Math.abs(directions.top), 
          Math.abs(directions.bottom)
        );
      }

      if (directions.left !== 0) directions.left = directions.left > 0 ? maxDirection : -maxDirection;
      if (directions.right !== 0) directions.right = directions.right > 0 ? maxDirection : -maxDirection;
      if (directions.top !== 0) directions.top = directions.top > 0 ? maxDirection : -maxDirection;
      if (directions.bottom !== 0) directions.bottom = directions.bottom > 0 ? maxDirection : -maxDirection;

      this.$emit('resize', {
        directions,
        nativeEvent: event,
      });

      this.startX = touch.clientX;
      this.startY = touch.clientY;
    },

    onResizeEnd() {
      if (this.resizing) {
        this.resizing = false;
        this.currentHandler = null;
        this.$emit('resize-end');
      }
    },

    getStencilShape() {
      const { left = 0, top = 0, width = 200 } = this.stencilCoordinates || {};
      const radius = width / 2;
      return {
        type: 'circle',
        center: {
          x: left + radius,
          y: top + radius,
        },
        radius,
      };
    },
  },
};
</script>

<style scoped>
.circle-stencil-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  pointer-events: none;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hole {
  position: absolute;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  background: transparent !important;
  z-index: 3;
  pointer-events: none;
}

.circle-stencil {
  background: transparent;
  pointer-events: none;
  z-index: 4;
}

.circle-preview {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  opacity: 1;
  background: transparent !important;
  pointer-events: none;
}

.resize-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  background: white;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  pointer-events: auto;
  z-index: 100;
}

.n { top: -7px; left: 50%; transform: translateX(-50%); cursor: n-resize; }
.s { bottom: -7px; left: 50%; transform: translateX(-50%); cursor: s-resize; }
.e { right: -7px; top: 50%; transform: translateY(-50%); cursor: e-resize; }
.w { left: -7px; top: 50%; transform: translateY(-50%); cursor: w-resize; }

:deep(.vue-advanced-cropper__background),
:deep(.vue-advanced-cropper__foreground),
:deep(.vue-advanced-cropper__overlay) {
  opacity: 0 !important;
  background: transparent !important;
}

:deep(.vue-advanced-cropper__image) {
  opacity: 1 !important;
}

:deep(.vue-advanced-cropper__boundary) {
  background: transparent !important;
}
</style>